<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!--구분-->
          <c-select
            codeGroupCd="SENIOR_CLASS_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="seniorClassCd"
            label="LBL0010238"
            v-model="searchParam.seniorClassCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <!--선해임명 목록-->
    <c-table
      ref="table"
      title="LBL0010240"
      tableId="equipmentInsp"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      rowKey="seniorId"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <!--검색-->
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
            <!--선택-->
            <c-btn v-if="editable" label="LBLSELECT" icon="check" @btnClicked="select" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "senior-dismissal-pop",
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: "seniorClassName",
            field: "seniorClassName",
            //구분
            label: "LBL0010238",
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: "relatedLawsName",
            field: "relatedLawsName",
            //관련법규
            label: "LBL0010215",
            style: 'width:250px',
            align: 'center',
            sortable: true,
          },
          {
            name: "seniorName",
            field: "seniorName",
            //선임명
            label: "LBL0010216",
            style: 'width:200px',
            align: "left",
            sortable: true,
          },
          {
            name: "qualifiyRequire",
            field: "qualifiyRequire",
            //세부법규
            label: "LBL0010243",
            align: "left",
            style: 'width:250px',
            sortable: true,
          },
          {
            name: "reportAppointName",
            field: "reportAppointName",
            //신고/선임
            label: "LBL0010242",
            align: "center",
            style: 'width:100px',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        seniorClassCd: null,
        useFlag: 'Y',
      },
      editable: true,
      listUrl: '',
      insertUrl: '',
      updateUrl: '',
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      disabled: false,
      isSave: false,
    };
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // role setting
      this.listUrl = selectConfig.mdm.senior.list.url;
      // url setting
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSG0010014', // 선/해임을 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  },
};
</script>
